import { cn } from '@/lib/utils';
import React, { FC, PropsWithChildren } from 'react';

export const RenderGrid: FC<
  PropsWithChildren & {
    isSearchPage: boolean;
    isMobile: boolean;
    galleryView: string;
    assetSize: Array<number>;
    gridColsLookup: Record<string, string>;
    isPublicRoute?: boolean;
  }
> = ({ children, isSearchPage, isMobile, galleryView, assetSize, gridColsLookup, isPublicRoute = false }) => (
  <div
    className={cn(
      'relative grid gap-5 p-4',
      isSearchPage && 'md:pt-32',
      isPublicRoute && 'pb-32',
      isMobile
        ? galleryView === 'list'
          ? ''
          : 'grid-cols-2'
        : galleryView === 'list'
        ? 'grid-cols-1 pb-32'
        : gridColsLookup[assetSize[0]] || 'grid-cols-7',
    )}
  >
    {children}
  </div>
);
