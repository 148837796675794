import { ReactNode, FC, PropsWithChildren, useCallback, useEffect, useState, useRef } from 'react';
import { ImageCard } from '@/components/image-card';
import { useAssetSelection } from '@/context/AssetSelectionContext';
import { cn } from '@/lib/utils';
import { ScrollArea } from '@/components/ui/scroll-area';
import { CustomPagination } from '@/components/pagination';
import { useDialog } from '@/context/DialogContext';
import { Asset, GetAssets } from '@/types/asset';
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuSeparator,
  ContextMenuTrigger,
} from '@/components/ui/context-menu';
import { useUserSettings } from '@/context/UserSettingsContext';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import { useTree } from '@/hooks/data/tree/useTree';
import { NodeType } from '@/types/tree';
import { useRouter } from 'next/router';
import { usePagination } from '@/hooks/usePagination';
import { ReorderAssetsCommand } from '@/hooks/commands/albums/ReorderAssetsCommand';
import { useCommandContext } from '@/context/CommandContext';
import { useCurrentPage } from '@/hooks/useCurrentPage';
import { Skeleton } from '@/components/ui/skeleton';
import { CustomDragLayer } from '@/components/custom-drag-layer';
import {
  Pen,
  ScanSearch,
  Tag,
  Trash2Icon,
  ListFilter,
  ArrowDownNarrowWide,
  ArrowUpNarrowWide,
  LayoutList,
  LayoutGrid,
  Share,
  Upload,
} from 'lucide-react';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/filter-select';
import { sortOptions, SortOrderOptions, SortState } from '@/types/sort';
import { Input } from '@/components/ui/input';
import { RenderGrid } from '@/components/render-grid';
import update from 'immutability-helper';

export type GalleryLayoutProps = {
  pageTitle: string;
  pageDescription?: string;
  data?: { assets?: GetAssets; nodeId?: string };
  resizeValue: number;
  isLoading?: boolean;
  emptyState?: ReactNode;
  sort?: SortState;
  handleOnSortChange?: (value: string) => void;
  handleOnSortOrderChange?: () => void;
  queryString?: string;
  handleOnSearch?: (value: React.ChangeEvent<HTMLInputElement>) => void;
  showFilterInput?: boolean;
  setShowFilterInput?: (showFilterInput: boolean) => void;
};

export const GalleryLayout = ({
  data,
  pageTitle,
  pageDescription,
  resizeValue,
  isLoading = false,
  emptyState,
  sort,
  handleOnSortChange,
  handleOnSortOrderChange,
  queryString,
  handleOnSearch,
  showFilterInput,
  setShowFilterInput,
}: GalleryLayoutProps) => {
  const [items, setItems] = useState<Array<Asset>>([]);

  const { selectAllAssets, selectedAssetIds, toggleAssetSelection, deselectAllAssets, contextAssetSelection } =
    useAssetSelection();
  const { toggleNodeSelection, setCurrentSelectedType, currentSelectedType, contextNodeSelection } = useTree();
  const { assetSize, galleryView, setGalleryView } = useUserSettings();
  const { apply } = useCommandContext();
  const { isMobile } = useBreakpoint();
  const { openModal } = useDialog();
  const { query } = useRouter();
  const { isSearchPage } = useCurrentPage();
  const { album, results, page, folder } = query;
  const { nodeId, assets } = data ?? {};
  const containerRef = useRef<HTMLDivElement>(null);

  const currentPage = (assets?.pagination?.offset ?? 0) / (assets?.pagination?.limit ?? 0) + 1;
  const totalCount = assets?.pagination?.count ?? 0;
  const pageSize = assets?.pagination?.limit ?? 0;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount: 2,
    pageSize,
  });

  const lastPage = paginationRange?.[paginationRange.length - 1];

  const images = assets?.assets;

  useEffect(() => {
    if (images) {
      setItems(images);
    }
  }, [images]);

  useEffect(() => {
    const callback = (event: KeyboardEvent) => {
      if (event.shiftKey && event.altKey && event.code === 'KeyL') {
        setGalleryView('list');
      }
      if (event.shiftKey && event.altKey && event.code === 'KeyT') {
        setGalleryView('tile');
      }
      // if (event.code === 'Delete' || event.code === 'Backspace') {
      //   openModal('deleteConfirmation');
      // }
    };

    if (!isMobile) {
      document.addEventListener('keydown', callback);
      return () => {
        document.removeEventListener('keydown', callback);
      };
    }
  }, [deselectAllAssets, items, isMobile, selectAllAssets, selectedAssetIds, setGalleryView]);

  const gridColsLookup: { [key: number]: string } = {
    100: 'grid-cols-2',
    80: 'grid-cols-3',
    60: 'grid-cols-4',
    40: 'grid-cols-5',
    20: 'grid-cols-6',
  };

  const reorderAssetsCommand = ReorderAssetsCommand(
    selectedAssetIds.map((asset) => asset.id),
    album,
  );

  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      if (!album) {
        return;
      }
      setItems((prevCards: Array<Asset>) =>
        update(prevCards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevCards[dragIndex] as Asset],
          ],
        }),
      );
    },
    [album],
  );

  const handleClick = (asset: Asset, index: number, metaKey?: boolean, shiftKey?: boolean, ctrlKey?: boolean) => {
    if (!assets) {
      return;
    }

    toggleNodeSelection({
      id: asset.nodeId,
      name: asset.name,
      assets: assets.assets,
      index,
      metaKey,
      shiftKey,
      ctrlKey,
    });
    setCurrentSelectedType(NodeType.Assets);
    toggleAssetSelection({
      id: asset.id,
      name: asset.name,
      assets: assets.assets,
      index,
      metaKey,
      shiftKey,
      ctrlKey,
    });
  };

  const handleRightClick = (asset: Asset) => {
    contextNodeSelection({ id: asset.nodeId, name: asset.name });
    setCurrentSelectedType(NodeType.Assets);
    contextAssetSelection({ id: asset.id, name: asset.name });
  };

  const saveCard = useCallback(
    (hoverIndex: number, oldPosition: number) => {
      if (!album) {
        return;
      }
      const position = Number(results ?? 0) * (Number(page ?? 1) - 1) + (hoverIndex + 1); //adding 1 because api accept position from 1 instead of 0
      reorderAssetsCommand.update({ position, oldPosition: oldPosition + 1 });
      apply(reorderAssetsCommand);
    },
    [apply, page, results, reorderAssetsCommand, album],
  );

  const renderImage = useCallback(
    (asset: Asset, index: number) => {
      return (
        <ContextMenu key={asset?.id || index}>
          <ContextMenuTrigger>
            <ImageCard
              key={asset?.id || index}
              index={index}
              asset={asset}
              moveCard={moveCard}
              saveCard={saveCard}
              width={galleryView === 'tile' ? 150 : 66}
              height={galleryView === 'tile' ? 150 : 66}
              isSelected={selectedAssetIds.some((selectedAsset) => selectedAsset.id === asset.id)}
              onClick={(event) => handleClick(asset, index, event?.metaKey, event?.shiftKey, event?.ctrlKey)}
              onContextMenu={() => handleRightClick(asset)}
              parentId={nodeId}
            />
          </ContextMenuTrigger>
          <ContextMenuContent className="w-40">
            <ContextMenuItem disabled>
              <Pen className="mr-2 size-4" />
              Edit asset
            </ContextMenuItem>
            <ContextMenuItem disabled>
              <Tag className="mr-2 size-4" />
              Edit Keywords
            </ContextMenuItem>
            <ContextMenuSeparator />
            <ContextMenuItem
              onClick={() => openModal('deleteConfirmation')}
              className="text-red-600 focus:bg-red-100 focus:text-red-600"
            >
              <Trash2Icon className="mr-2 size-4" />
              Delete Asset{selectedAssetIds.length > 1 ? 's' : ''}
            </ContextMenuItem>
          </ContextMenuContent>
        </ContextMenu>
      );
    },
    [
      galleryView,
      moveCard,
      openModal,
      selectedAssetIds,
      setCurrentSelectedType,
      toggleAssetSelection,
      toggleNodeSelection,
      saveCard,
      nodeId,
      handleClick,
      handleRightClick,
    ],
  );
  {
    items?.map((asset, index) => renderImage(asset, index));
  }

  return (
    <>
      {!isSearchPage && (
        <>
          <div className="mt-12 flex flex-col bg-neutral-100 px-3 dark:bg-neutral-800">
            <div className="flex items-center justify-between">
              <div className="flex">
                {currentSelectedType === NodeType.Folders && folder && (
                  <div
                    className="mr-4 flex cursor-pointer"
                    onClick={() => {
                      openModal('uploadAsset');
                    }}
                  >
                    <Upload className="mr-2 size-4 text-neutral-400" />
                    <div className="cursor-pointer text-xs">Upload assets</div>
                  </div>
                )}
                {currentSelectedType === NodeType.Albums && nodeId && album && (
                  <div
                    className="mr-4 flex cursor-pointer"
                    onClick={() => {
                      openModal('createShare', 'createShare', {
                        id: nodeId,
                      });
                    }}
                  >
                    <Share className="mr-2 size-4 text-neutral-400" />
                    <div className="cursor-pointer text-xs">Share</div>
                  </div>
                )}
              </div>
              <div className="flex h-12 items-center">
                <ListFilter
                  onClick={() => setShowFilterInput && setShowFilterInput(!showFilterInput)}
                  data-cy="gallery-filter-icon"
                  className="mx-4 size-5 cursor-pointer text-violet-500"
                />

                <Select value={sort?.value} onValueChange={handleOnSortChange}>
                  <SelectTrigger>
                    <div className="flex size-full items-center">
                      <div className="flex size-full items-center justify-center bg-white px-2 dark:bg-neutral-950">
                        <SelectValue placeholder="Sort" />
                      </div>
                    </div>
                  </SelectTrigger>
                  <SelectContent>
                    {sortOptions.map(({ label, value }) => (
                      <SelectItem key={value} value={value}>
                        <div>{label}</div>
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                {sort?.order === SortOrderOptions.ASC ? (
                  <ArrowDownNarrowWide
                    onClick={handleOnSortOrderChange}
                    data-cy="gallery-sort-order-icon"
                    className="ml-4 mr-2 size-4 cursor-pointer"
                  />
                ) : (
                  <ArrowUpNarrowWide
                    onClick={handleOnSortOrderChange}
                    data-cy="gallery-sort-order-icon"
                    className="ml-4 mr-2 size-4 cursor-pointer"
                  />
                )}
                <LayoutList
                  onClick={() => setGalleryView('list')}
                  data-cy="gallery-layout-list-icon"
                  className={cn('mx-2 size-4 cursor-pointer', galleryView === 'list' && 'text-violet-500')}
                />
                <LayoutGrid
                  onClick={() => setGalleryView('tile')}
                  data-cy="gallery-layout-grid-icon"
                  className={cn('mx-2 size-4 cursor-pointer', galleryView === 'tile' && 'text-violet-500')}
                />
                {/* <ZoomOut data-cy="gallery-zoom-out-icon" className="mx-2 size-4 cursor-pointer" />
            <ZoomIn data-cy="gallery-zoom-in-icon" className="ml-2 mr-4 size-4 cursor-pointer" /> */}
              </div>
            </div>
          </div>
          {showFilterInput && (
            <div className="flex items-center bg-white transition duration-150 dark:bg-neutral-950">
              <ListFilter data-cy="gallery-search-filter-icon" className="mx-2 size-4" />
              <div className="text-sm font-bold">Filter: </div>
              <Input
                defaultValue={queryString}
                placeholder="Type here to filter results in this view"
                onChange={handleOnSearch}
                className="z-20 h-12 w-full rounded-none border-0 bg-white focus-within:outline-none focus-within:ring-0 focus-within:ring-offset-0 focus-within:duration-0 dark:bg-neutral-950"
              />
            </div>
          )}
        </>
      )}
      {isLoading ? (
        <RenderGrid
          isSearchPage={isSearchPage}
          isMobile={isMobile}
          galleryView={galleryView}
          assetSize={assetSize}
          gridColsLookup={gridColsLookup}
        >
          {Array.from(Array(16)).map((_, index) => (
            <div
              key={`gallery-skeleton-${index}`}
              className={cn(
                isMobile
                  ? galleryView === 'list'
                    ? 'grid grid-cols-[100px_1fr] gap-5 space-y-3'
                    : 'grid grid-rows-[1fr_auto] gap-3'
                  : galleryView === 'list'
                  ? 'grid grid-cols-[100px_1fr] gap-5 space-y-3'
                  : 'grid gap-2',
              )}
            >
              <Skeleton className="flex aspect-square h-auto w-full bg-neutral-100 dark:bg-neutral-900" />
              <div className="!mt-0 flex flex-col justify-center gap-2 text-sm">
                <Skeleton className="h-4 w-11/12 bg-neutral-100 dark:bg-neutral-900" />
                <Skeleton className="h-6 w-9/12 bg-neutral-100 dark:bg-neutral-900" />
              </div>
            </div>
          ))}
        </RenderGrid>
      ) : (
        <>
          {isMobile && (
            <div className="mt-20">
              <div className="mb-5 flex flex-col items-center">
                <h2 className="text-2xl font-semibold tracking-tight">{pageTitle}</h2>
                <p className="text-sm text-neutral-500 lg:ml-0">{pageDescription}</p>
              </div>
            </div>
          )}
          {items?.length ? (
            <>
              <ScrollArea className="h-full" ref={containerRef}>
                <CustomDragLayer items={items} selectedAssetIds={selectedAssetIds} containerRef={containerRef} />
                <RenderGrid
                  isSearchPage={isSearchPage}
                  isMobile={isMobile}
                  galleryView={galleryView}
                  assetSize={assetSize}
                  gridColsLookup={gridColsLookup}
                >
                  {items.map((asset, index) => renderImage(asset, index))}
                </RenderGrid>
              </ScrollArea>
              {assets?.pagination && assets?.pagination.count > assets?.pagination.limit && (
                <div className="absolute inset-x-0 bottom-0">
                  <CustomPagination
                    totalCount={totalCount}
                    siblingCount={resizeValue < 52.5 ? 1 : 2}
                    onlyIcons={resizeValue < 44}
                    currentPage={currentPage}
                    pageSize={pageSize}
                    className="relative z-20 p-4"
                    deselectAssetsOnPageChange
                    handlePrev={{
                      href: {
                        query: {
                          ...query,
                          page: currentPage === 1 ? 1 : currentPage - 1,
                        },
                      },
                      onClick: deselectAllAssets,
                      disabled: Number(currentPage) === 1 || Number(currentPage) === 0,
                    }}
                    handleNext={{
                      href: {
                        query: {
                          ...query,
                          page: currentPage === lastPage ? lastPage : currentPage === 0 ? 2 : currentPage + 1,
                        },
                      },
                      onClick: deselectAllAssets,
                      disabled: currentPage === lastPage,
                    }}
                  />
                  <div className="pointer-events-none absolute inset-0 -top-14 z-10 [background-image:linear-gradient(to_top,white_30%,transparent_100%)] dark:[background-image:linear-gradient(to_top,black_30%,transparent_100%)]" />
                  <div className="pointer-events-none absolute inset-0 -top-5 z-10 backdrop-blur-[5px] [mask-image:linear-gradient(to_top,white_50%,transparent_100%)]" />
                </div>
              )}
            </>
          ) : emptyState ? (
            emptyState
          ) : (
            <div className="flex h-screen flex-col items-center justify-center gap-2">
              <ScanSearch className="mb-10 size-20 text-neutral-300 dark:text-neutral-600" strokeWidth={1} />
              <h4 className="text-balance text-center text-2xl">No Assets Found</h4>
              <div className="max-w-md text-balance px-16 text-center text-neutral-600">
                The selected {currentSelectedType === NodeType.Assets ? 'asset' : 'folder'} does not contain any assets.
              </div>
            </div>
          )}
        </>
      )}
      ;
    </>
  );
};
