import Image from 'next/image';
import { FC } from 'react';
import { cn } from '@/lib/utils';
import { Asset, Variants } from '@/types/asset';
import { File } from 'lucide-react';

export const DraggedImage: FC<{
  index: number;
  name: string;
  width: number;
  height: number;
  stackedAssetsLength: number;
  draggedThumbnailUrl?: string;
  asset?: Asset;
  classname?: string;
}> = ({ index, name, width, height, stackedAssetsLength, draggedThumbnailUrl, asset, classname }) => {
  const thumbnailUrl = draggedThumbnailUrl ?? asset?.variants[Variants.ThumbnailInternal]?.url;

  return (
    <div
      className={cn(
        'absolute flex size-full origin-center overflow-hidden rounded-md shadow-lg transition-all duration-200',
      )}
      style={{
        transform: `rotate(${(30 / stackedAssetsLength) * index}deg)`,
        zIndex: stackedAssetsLength - index,
      }}
    >
      {thumbnailUrl ? (
        <Image
          draggable="false"
          src={thumbnailUrl}
          alt={name}
          width={width}
          height={height}
          className={cn('size-auto object-cover', classname)}
        />
      ) : (
        <div className="flex size-full items-center justify-center rounded-md bg-white hover:cursor-pointer dark:bg-neutral-950">
          <File className="size-14 stroke-neutral-500 dark:stroke-neutral-700" strokeWidth={1} />
        </div>
      )}
    </div>
  );
};
