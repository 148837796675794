import { useEffect, useRef, useState } from 'react';
import { cn } from '@/lib/utils';
import { useRouter } from 'next/router';
import { ImageCardProps } from '@/types';
import { useUserSettings } from '@/context/UserSettingsContext';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import { DropTargetMonitor, useDrag, useDrop } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { DragItem, DraggableItems } from '@/types/dragDrop';
import { Variants } from '@/types/asset';
import { Trash2 } from 'lucide-react';
import { AssetThumbnail } from '@/components/asset-thumbnail';
import type { Identifier, XYCoord } from 'dnd-core';

export function ImageCard({
  asset: { nodeId, name, description, variants, id, trashed, type },
  width,
  height,
  className,
  isSelected,
  parentId,
  index,
  moveCard,
  saveCard,
  ...props
}: ImageCardProps) {
  const { isMobile } = useBreakpoint();
  const { galleryView, showInspector, showSidebar } = useUserSettings();

  const thumbnailUrl = variants[Variants.ThumbnailInternal]?.url;
  const router = useRouter();
  const ref = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);
  const [imageRect, setImageRect] = useState({ width: 0, height: 0 });

  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: DraggableItems.ASSET,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    drop(item) {
      saveCard(item.index ?? 0, item.oldPosition ?? 0);
    },
    // Card Hover
    hover(item: DragItem, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index ?? -1;
      const hoverIndex = index ?? -1;

      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 3;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveCard(dragIndex, hoverIndex);

      item.index = hoverIndex;
    },
  });

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      setImageRect({ width: entries[0].contentRect.width, height: entries[0].contentRect.height });
    });

    if (imageRef.current) {
      observer.observe(imageRef.current);
    }

    return () => {
      if (imageRef.current) {
        observer.unobserve(imageRef.current);
      }
    };
  }, []);

  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type: DraggableItems.ASSET,
      item: {
        type: DraggableItems.ASSET,
        nodeId,
        id,
        currentParent: parentId,
        oldPosition: index,
        index,
        name,
        thumbnailUrl,
        width: imageRect.width ?? 50,
        height: imageRect.height ?? 50,
        trashed,
      } as DragItem,
      collect: (monitor) => ({
        isDragging: Boolean(monitor.isDragging()),
      }),
    }),
    [
      imageRect.width,
      imageRect.height,
      thumbnailUrl,
      name,
      trashed,
      nodeId,
      id,
      parentId,
      index,
      showInspector,
      showSidebar,
    ],
  );

  drag(drop(ref));

  useEffect(() => {
    if (isDragging && !isSelected) {
      props.onClick();
    }
  }, [isDragging, isSelected, props]);

  const handleDoubleClick = () => {
    void router.push({
      pathname: `/asset/[id]`,
      query: {
        id,
        // Conditionally include 'folder' and 'album' in the query parameters
        // Only add 'folder' and 'album' if they exist in the current router query
        ...(router.query.folder && { folder: router.query.folder }),
        ...(router.query.album && { album: router.query.album }),
      },
    });
  };

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, []);

  return (
    <div
      key={id}
      ref={ref}
      data-handler-id={handlerId}
      className={cn(
        className,
        isMobile
          ? galleryView === 'list'
            ? 'grid grid-cols-[100px_1fr] gap-5 space-y-3'
            : 'grid grid-rows-[1fr_auto] gap-3'
          : galleryView === 'list'
          ? 'grid grid-cols-[100px_1fr] gap-5 space-y-3'
          : 'grid gap-2',
        isDragging ? 'opacity-50' : 'opacity-100',
      )}
      {...props}
    >
      <div
        className={cn(
          'relative overflow-hidden rounded-md duration-200',
          isSelected &&
            'ring-2 ring-neutral-500 ring-offset-2 ring-offset-white duration-200 dark:ring-offset-neutral-950',
        )}
      >
        {trashed && (
          <div className="absolute inset-0 flex items-center justify-center ">
            <Trash2 className="size-12 stroke-red-500 dark:stroke-red-800" strokeWidth={1.5} />
          </div>
        )}
        <AssetThumbnail
          name={name}
          fileType={type}
          thumbnail={thumbnailUrl}
          iconSize={200}
          draggable={false}
          width={width}
          height={height}
          imageRef={imageRef}
          iconContainerRef={imageRef}
          fill={false}
          imageClassName={cn(
            'aspect-square h-auto w-full rounded-md bg-neutral-100 object-contain transition-all hover:cursor-pointer dark:bg-neutral-900',
            { 'opacity-50': trashed },
          )}
          iconClassName="size-14 stroke-neutral-500 dark:stroke-neutral-700"
          imageContainerClassName=""
          iconContainerClassName="flex aspect-square h-auto w-full items-center justify-center rounded-md bg-neutral-100 hover:cursor-pointer dark:bg-neutral-900"
          onDoubleClick={handleDoubleClick}
        />
      </div>
      <div className="!mt-0 flex flex-col justify-center text-sm">
        <h3 className="line-clamp-1 break-all font-medium text-neutral-950 dark:text-neutral-50 lg:line-clamp-2 lg:h-10">
          {name}
        </h3>
        <p className="line-clamp-3 h-12 text-xs text-neutral-500">{description}</p>
      </div>
    </div>
  );
}
